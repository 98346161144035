
import { defineComponent, computed } from "vue";
import { i18n } from "@/i18n";

export default defineComponent({
  name: "VideosLibrary",
  setup() {
    const { t } = i18n.global;

    const list = computed(() => [
      // {
      //   title: t("libraryVideos.videos.a.title"),
      //   file: t("libraryVideos.videos.a.file"),
      //   imageUrl: t("libraryVideos.videos.a.imageUrl")
      // },
      // {
      //   title: t("libraryVideos.videos.b.title"),
      //   file: t("libraryVideos.videos.b.file"),
      //   imageUrl: t("libraryVideos.videos.b.imageUrl")
      // },
      // {
      //   title: t("libraryVideos.videos.c.title"),
      //   file: t("libraryVideos.videos.c.file"),
      //   imageUrl: t("libraryVideos.videos.c.imageUrl")
      // }
      {
        title: t("libraryVideos.videos.d.title"),
        file: t("libraryVideos.videos.d.file"),
        imageUrl: t("libraryVideos.videos.d.imageUrl")
      },
      {
        title: t("libraryVideos.videos.e.title"),
        file: t("libraryVideos.videos.e.file"),
        imageUrl: t("libraryVideos.videos.e.imageUrl")
      },
      {
        title: t("libraryVideos.videos.f.title"),
        file: t("libraryVideos.videos.f.file"),
        imageUrl: t("libraryVideos.videos.f.imageUrl")
      },
      {
        title: t("libraryVideos.videos.g.title"),
        file: t("libraryVideos.videos.g.file"),
        imageUrl: t("libraryVideos.videos.g.imageUrl")
      },
      {
        title: t("libraryVideos.videos.h.title"),
        file: t("libraryVideos.videos.h.file"),
        imageUrl: t("libraryVideos.videos.h.imageUrl")
      }
    ]);

    const thumbClick = (idx: number) => {
      const item = list.value[idx];
      const imageRepo =
        "https://api.metacriacoes.com/friesland-campina/app-friso/v1/?action=getFile&type=vid&file=library/";
      const fileUrl = imageRepo + item["file"];
      window.open(fileUrl);
    };

    return {
      t,
      list,
      thumbClick
    };
  }
});
